
import { defineComponent, ref, onMounted, createVNode } from "vue";
import { notification, Modal } from "ant-design-vue";
import {
  CopyOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  LogoutOutlined,
  DownOutlined,
  QuestionCircleFilled,
  DeleteOutlined,
  CloseCircleOutlined
} from "@ant-design/icons-vue";

import authServices from "./services/auth";
import router from "./router";
import IProfile from "./interface/profile";

import candidateService from "./services/candidate";
import questionService from "./services/questionProfile";
import globalvariable from "./services/globalVariable"
import commonService from './services/common'
import { useRoute } from "vue-router";

interface MenuInfo {
  key: string;
  keyPath: string[];
  item: any;
  domEvent: MouseEvent;
}

export default defineComponent({
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    SettingOutlined,
    LogoutOutlined,
    DownOutlined,
    QuestionCircleFilled,
    DeleteOutlined
  },
  setup(props, { emit }) {
    const selectedKeys = ref(["1"]);
    const collapsed = ref(false);
    const searchCandidate = ref();
    const allCandidates = ref([]);
    const activeCandidateKey = ref([])
    const candidateFilterType = ref()
    const avilablecredit = ref()
    const route = useRoute();
    const profile = ref<IProfile>(
      JSON.parse(localStorage.getItem("profile") || "{}")
    );
    const getAvailableCreditPoint = async () => {
      avilablecredit.value = localStorage.available_credits
    }
    const updateMenu = () => {
      profile.value = JSON.parse(localStorage.getItem("profile") || "{}");
    };
    const userProfile = () => {
      router.push("/profile");
    }
    const dashboardPage = () => {
      if(route.name != 'Authorize') {
        if(profile.value.role_id === 1){
          router.push('/admin-agencies')
        } else if (profile.value.role_id === 2 || profile.value.role_id === 3){
          router.push("/dashboard");
        }
      }      
    }
    const billingPage = () => {
      router.push("/billing");
    }
    const toogleMenu = () => {
      event.stopPropagation();
      collapsed.value = !collapsed.value
    }
    const logout = async () => {
       try {
        const res = await authServices.logout();
        if (res.data.message === 'Successfully logged out') {
          await notify("Success", res.data.message, "success");
          router.push("/login");
          activeCandidateKey.value = null
          delete localStorage.accessToken
          delete localStorage.profile
          localStorage.clear()
        }
      } catch (error: any) {
        if (error.response) {
          await notify("Error", error.response.data.data.message, "error");
        }
      }
    };
    const onMenuClick = ({ key }: MenuInfo) => {
      if(key === '1'){
         router.push("/questions");
      }
      if(key === '2'){
         router.push("/users");
      }
      if(key === '3'){
         router.push("/billing");
      }
    };
    const onCandidateSearch = async () => {
      getAllcandidate(candidateFilterType.value)
    };
    const getAllcandidate = async (value?: any) => {
      activeCandidateKey.value = null
      allCandidates.value = [];
      avilablecredit.value = localStorage.available_credits
      updateMenu()
      try {
        const data = {
          order: value === 'All' ? '' : value === 'Oldest' ? 'asc' : value === 'New' ? 'desc' : '',
          search_by: searchCandidate?.value
        }
        const res = await candidateService.getAllCandidates(data);
        if (res.data.data.success) {
          allCandidates.value = res.data.data.candidates;
          if(allCandidates.value.length) {
            const cadidateId: any = +localStorage.getItem("activeCandidateId")
            if(cadidateId !== null && cadidateId !== 0) {
              activeCandidateKey.value = null
              setTimeout(() => {
                activeCandidateKey.value = cadidateId
                 setTimeout(() => {
                  localStorage.removeItem("activeCandidateId");
                  }, 500);
              }, 100);
            } else {
              activeCandidateKey.value = allCandidates.value[0].id
            }
          } else {
            activeCandidateKey.value = undefined
          }
          localStorage.available_credits = res.data.data.available_credits
          avilablecredit.value = localStorage.available_credits
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    }
    const candidateDetails = async (item: any) => {
      activeCandidateKey.value = item.id
      emit("activeCandidateKey", item.id)
    }
    const candidateFilters = async (value: any) => {
      candidateFilterType.value = value
      getAllcandidate(value)
    }
    const superAdminPages = async (data:  any) => {
      if(data == 'agencies'){
        router.push('/admin-agencies')
      } else if(data == 'prices') {
        router.push('/admin-prices')
      } else {
        router.push('/admin-organisations')
      }
    }
    onMounted(() => {
      candidateFilterType.value = 'All'
      setTimeout(() => {
        avilablecredit.value = localStorage.available_credits
      }, 1000);
    })
    const checkPermission = (pName: any, subPage?: any) => {
      const allPermissions = JSON.parse(localStorage.getItem('permission'))
      const filterpermisssion = allPermissions.filter((itm: any) => {
        return itm.name === pName
      })
      let filterResult
      if (filterpermisssion.length) {
        if (subPage) {
          filterResult = filterpermisssion[0].data[subPage].filter((itm: any) => {
              return itm.name === pName && itm.type === 'view'
            })
        } else {
           filterResult = filterpermisssion[0].data.filter((itm: any) => {
              return itm.name === pName && itm.type === 'view'
            })
        }
        return filterResult[0].value
      } else {
        return false
      }
    }
    // question Profile page
    const allQuestions = ref([]);
    const activeQuestionKey =ref([]);
    const addqProfileForm = ref<boolean>(false);
    const qProfileName = ref()
    const saveNewProfileLoading = ref<boolean>(false);
    const getAllQuestionProfile = async () => {
      activeQuestionKey.value = null
       try {
        const res = await questionService.getQProfile();
        if (res.data.data.success) {
          allQuestions.value = res.data.data.profiles;
          if(allQuestions.value.length) {
            activeQuestionKey.value = allQuestions.value[0].id
          }
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    }
    const questionDetails = async (item: any) => {
      activeQuestionKey.value = item.id
      emit('activeQuestionKey', item.id)
    }
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const addNewQuestionProfile = async () => {
      qProfileName.value =''
      addqProfileForm.value = !addqProfileForm.value
    }
    const saveNewQuestionProfile = async () => {
      if(qProfileName.value) {
         saveNewProfileLoading.value = true;
         const data = {
           profile_name:qProfileName.value
         }
      try {
        const res = await questionService.addQProfile(data);
        if (res.data.data.success) {
          addqProfileForm.value = false
          await notify("Success", res.data.data.message, "success");
          getAllQuestionProfile();
        }
        saveNewProfileLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.data.message, "error");
          saveNewProfileLoading.value = false;
        }
      }
      } 
    }
    const showDeleteQuestionConfirm = async(questionId: any) => {
        Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode(
          "p",
          {},
          "Do you really want to delete this profile? This process cannot be undone."
        ),
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await deleteQuestionProfile(questionId);
        },
      });
    }
    const deleteQuestionProfile = async (id: any) => {
       try {
        const res = await questionService.deleteQProfile(id);
        if (res.data.data.success) {
          getAllQuestionProfile();
          notify("Success", res.data.data.message, "success");
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          await notify("Error", error.response.data.data.message, "error");
        }
      }
    }
    return {
      selectedKeys,
      avilablecredit,
      collapsed,
      getAvailableCreditPoint,
      dashboardPage,
      billingPage,
      userProfile,
      logout,
      profile,
      updateMenu,
      searchCandidate,
      onCandidateSearch,
      onMenuClick,
      getAllcandidate,
      allCandidates,
      activeCandidateKey,
      candidateDetails,
      candidateFilterType,
      candidateFilters,
      // updateMenuIndex,
      checkPermission,
      commonService,
      // question Profile page
      allQuestions,
      activeQuestionKey,
      getAllQuestionProfile,
      questionDetails,
      addNewQuestionProfile,
      addqProfileForm,
      qProfileName,
      saveNewQuestionProfile,
      saveNewProfileLoading,
      showDeleteQuestionConfirm,
      superAdminPages,
      toogleMenu
    };
  },
});
